import React from "react";
import ExtendedTitle from "../components/extended-title";
import Layout from "../components/layout";
import { useStaticQuery } from "gatsby";
import Marquee from "react-fast-marquee";
import { graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import Heading from "../components/heading";
import SubHeading from "../components/subheading";
import Button from "../components/button";
import ArrowButton from "../components/arrow-button";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { Seo } from "../components/seo";

const Culture = () => {
	const data = useStaticQuery(graphql`
		query CultureQuery {
			directus {
				hero_images(filter: { location: { _eq: "culture" } }) {
					image {
						id
						imageFile {
							childImageSharp {
								gatsbyImageData(width: 1200, quality: 75)
							}
						}
					}
				}
				clients {
					id
					name
					image {
						imageFile {
							childImageSharp {
								gatsbyImageData(width: 300, quality: 75)
							}
						}
						id
					}
				}
				core_values(filter: { status: { _eq: "published" } }) {
					content
					title
					id
				}
				team(filter: { status: { _eq: "published" } }) {
					name
					photo {
						id
						imageFile {
							childImageSharp {
								gatsbyImageData(
									width: 750
									formats: WEBP
									quality: 75
								)
							}
						}
					}
					id
				}
			}
		}
	`);

	const clients = data.directus.clients ?? [];
	const half = Math.ceil(clients.length / 2);

	const firstHalf = clients.slice(0, half);
	const secondHalf = clients.slice(half);

	const [swiper, setSwiper] = React.useState(null);

	const nexto = () => {
		swiper.slideNext();
	};

	const prevto = () => {
		swiper.slidePrev();
	};

	return (
		<Layout>
			<main className="">
				<ExtendedTitle
					subtitle="The pause button of life"
					backButtonTitle="Contact"
					backButtonTo="/contact"
					image={data.directus.hero_images[0].image}
				>
					Over ons
				</ExtendedTitle>

				<section className="bg-gray text-off-white flex flex-col justify-center py-36 px-8">
					<div className="max-w-4xl mx-auto text-base">
						<p className="text-xl md:text-2xl font-bold mb-8">
							Begonnen in 2011 met nog steeds dezelfde visie “It
							starts with a Spark.”
						</p>
						<p className="mb-8">
							We Make It Spark (of Spark voor intimi) is een
							fotografiebureau gericht op de cultuur, sport en
							entertainment branche. “Hier had ik bij willen zijn”
							is ons grootste compliment. We geloven daarbij
							heilig in de kracht van samenwerking. Met één
							fotograaf, maar ook met twintig of meer tegelijk.
						</p>
						<p className="mb-8 text-lg font-bold italic">
							“Iedereen maakt mooi beeld”, roepen we wel eens
							gekscherend op kantoor.
						</p>
						<p className="mb-8">
							Onze kracht ligt in de dienstverlening, waarbij we
							de juiste vaklieden koppelen aan de opdracht en de
							taal van de fotograaf spreken voor de juiste
							vertaling. Met als eindresultaat fantastisch beeld.
							Want uiteindelijk zijn we beeldmakers, en voor
							kwalitatief goed beeld staan wij elke dag op.
						</p>
						<p className="mb-8">
							De meesten kennen ons werk van de grootschalige
							dance- en sportevenementen zoals Defqon.1, Decibel,
							Mysteryland, 7th Sunday, Intents Festival, Dam tot
							Damloop en de Marathon van Amsterdam.
						</p>
					</div>

					<div className="max-w-7xl mx-auto mt-16">
						<StaticImage
							src="../images/team.png"
							alt="We Make It Spark team"
						/>
					</div>
				</section>

				<section className="bg-gray border-y-2 border-gray">
					<Swiper
						modules={[Autoplay]}
						autoplay
						// className="min-h-[450px] h-[20vh] md:h-[70vh] md:min-h-[450px]"
						loop={true}
						slidesPerView={1}
						slidesPerGroupSkip={2}
						centeredSlides={true}
						spaceBetween={2}
						breakpoints={{
							768: {
								slidesPerView: 2,
							},
						}}
						onSwiper={(s) => {
							setSwiper(s);
						}}
					>
						{data.directus.core_values.map((item, i) => (
							<SwiperSlide className="h-full" key={item.id}>
								<div className="bg-off-white rounded-spark h-full p-8 md:p-16 lg:p-24 flex justify-center items-center">
									<div>
										<Heading as="h2" className="mb-8">
											{item.title}
										</Heading>
										<p className="text-sm">
											{item.content}
										</p>
									</div>
								</div>
							</SwiperSlide>
						))}

						<ArrowButton
							onClick={prevto}
							className="absolute bottom-0 m-4 z-50"
							iconClassName="rotate-180"
						/>
						<ArrowButton
							onClick={nexto}
							className="absolute bottom-0 right-0 m-4 z-50"
						/>
					</Swiper>
				</section>

				<section className="bg-gray py-56 text-white">
					<div className="px-8 max-w-7xl mx-auto">
						<SubHeading>Team SPARK</SubHeading>
						<Heading>It smells like team spirit</Heading>
					</div>

					<div className="grid grid-cols-1 md:grid-cols-4 gap-8 mt-16 max-w-8xl px-8">
						{data.directus.team.map((item, i) => (
							<div
								key={item.name}
								className="relative rounded-lg overflow-hidden"
							>
								<GatsbyImage
									className="w-auto h-full object-cover rounded-lg"
									alt={item.name}
									image={getImage(item.photo.imageFile)}
									imgClassName="rounded-lg"
								/>

								<span className="absolute bottom-4 left-4 border border-zinc-600 px-4 py-2 rounded-full text-sm">
									{item.name}
								</span>
							</div>
						))}
					</div>

					<p className="text-lg text-center mt-16 italic px-8">
						... en samen met nog 60+ vaste partners maken wij
						dagelijks content waar honderdduizenden mensen plezier
						van hebben.
					</p>
				</section>

				<section className="bg-off-white py-56">
					<div className="px-8 max-w-7xl mx-auto">
						<SubHeading>Opdrachtgevers</SubHeading>
						<Heading>Vrienden van de familie</Heading>
					</div>
					<div className="mt-24 mb-56">
						<Marquee gradient={false} className="mb-4">
							{firstHalf.map((client, i) => (
								<div
									key={client.id}
									className="bg-black rounded-lg mx-3 shadow-lg py-4 invert"
								>
									<GatsbyImage
										className="w-40 md:w-72 h-auto object-cover"
										alt={client.name}
										image={getImage(client.image.imageFile)}
									/>
								</div>
							))}
						</Marquee>
						<Marquee gradient={false} direction="right">
							{secondHalf.map((client, i) => (
								<div
									key={client.id}
									className="bg-black rounded-lg mx-3 shadow-lg py-4 invert"
								>
									<GatsbyImage
										className="w-40 md:w-72 h-auto object-cover"
										alt={client.name}
										image={getImage(client.image.imageFile)}
									/>
								</div>
							))}
						</Marquee>
					</div>
					<div className="max-w-3xl mx-auto px-8 text-center">
						<Heading className="mb-8">Never a dull moment</Heading>
						<p className="mb-8">
							Er is altijd wel iets te beleven. We zoeken graag
							naar 'the ultimate joys of life', en leggen ze voor
							je vast. Nieuwsgierig?
						</p>
						<Button to="/contact" dark={true}>
							Neem contact op
						</Button>
					</div>
				</section>
			</main>
		</Layout>
	);
};

export default Culture;

export const Head = ({ location }) => (
	<Seo
		pathname={location.pathname}
		pageTitle="Cultuur"
		subtitle="Over SPARK"
		title="Cultuur - SPARK - fotografie voor cultuur, sport en entertainment"
		description="Bij de nieuwe generatie content makers hoort een uitgesproken cultuur. Want om de aandacht naar je toe te trekken moet je uniek zijn in wat je doet."
	/>
);
